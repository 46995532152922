import { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import api from '../../../../services/api';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNavigate } from 'react-router-dom';
import Loading from '../../../../load/loading';
import MenuItem from '@mui/material/MenuItem';
import HeaderSession from '../../../../utils/headerSession';
import { Card, Container, InputBase, TextField, Typography } from '@mui/material';
import RegisterAccess from '../../../../utils/registerAccess';
import { makeStyles } from '@material-ui/core';
import ListaVazia from '../../../../utils/listaVazia';
import Date from '../../../../utils/date';
import StatusEstabelecimento from '../../../../utils/statusEstabelecimento';


const useStyles = makeStyles((theme) => ({
  hover: { '&:hover': { backgroundColor: '#85287d8a' }, cursor: 'pointer' },
}))

const ListaSolicitacoesDesalfandegamento = () => {
  const classes = useStyles();

  const [solicitacoes, setSolicitacoes] = useState('');
  const [status, setStatus] = useState('Análise');

  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/declaracao-desalfandegamentos?status=${status}`)
      .then(response => {
        setSolicitacoes(response.data.declaracaoDesalfandegamentos)
      }).catch(err => {
        // console.log(err)
      })
  }, [status])


  // const filtraReclamacoes = (valor) => {
  //   setSolicitacoes(filter.filter((espedienteGeral) => (espedienteGeral?.nome?.toLowerCase().includes(valor.toLowerCase()))))
  // }


  return (

    <Container>
      <RegisterAccess page={'lista de solicitações de importação'} />
      <HeaderSession title={'SOLICITAÇÕES DE IMPORTAÇÃO'} />

      <Card style={{ padding: 20 }}>
        <div style={{ display: 'flex', border: '1px solid #ddd', borderRadius: 5 }}>
          <InputBase
            style={{ width: '100%' }}
            sx={{ ml: 1, flex: 1 }}
            autoFocus
            placeholder="Filtra aquí pelo nome"
            inputProps={{ 'aria-label': 'Filtrar lista' }}
          // onChange={(e) => { filtraReclamacoes(e.target.value) }}
          />
          <TextField
            style={{ width: 200 }}
            type="text"
            label="Estado"
            select
            size="small"
            variant="outlined"
            onChange={(e) => { setStatus(e.target.value === 'Geral' ? '' : e.target.value) }}
            defaultValue={'Análise'}
          >
            <MenuItem value='Geral'>Geral</MenuItem>
            <MenuItem value='Análise'>Análise</MenuItem>
            <MenuItem value='Aprovado'>Aprovado</MenuItem>
            <MenuItem value='Reprovado'>Reprovado</MenuItem>

          </TextField>
        </div>


        {solicitacoes ?
          solicitacoes.length > 0 ?

            <Card variant="outlined" sx={{ width: '100%', overflow: 'hidden' }} >
              <TableContainer id={'listaSolicitacoesImportacao'}>
                <Table size="small" stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" ><Typography noWrap> <strong> Importador </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Nº de Declaração </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Ponto de Entrada </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Destino </strong></Typography></TableCell>
                      {/* <TableCell align="center" ><Typography noWrap> <strong> Fatura Final Nº </strong></Typography></TableCell> */}
                      <TableCell align="center" ><Typography noWrap> <strong> Estado </strong></Typography></TableCell>
                      <TableCell align="center" ><Typography noWrap> <strong> Data da Solicitação </strong></Typography></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {solicitacoes.map((solicitacao) => (
                      <TableRow key={solicitacao._id} className={classes.hover} onClick={() => { navigate(`/admin/dmts/gest/solicitacao-de-desalfandegamento/${solicitacao?.solicitacaoImportacaoId}`) }}>

                        <TableCell ><Typography noWrap> {solicitacao.importador?.nome}</Typography></TableCell>
                        <TableCell  ><Typography > {solicitacao.numeroDeclaracao}</Typography></TableCell>
                        <TableCell  ><Typography > {solicitacao.pontoEntrada}</Typography></TableCell>
                        <TableCell  ><Typography noWrap> {solicitacao.destino}</Typography></TableCell>
                        {/* <TableCell  ><Typography noWrap> {solicitacao.paisOrigem}</Typography></TableCell> */}
                        <TableCell align="center" ><Typography noWrap> <StatusEstabelecimento status={solicitacao?.status} /> </Typography></TableCell>
                        <TableCell align="center" ><Typography noWrap> <Date date={solicitacao?.createdAt} /> </Typography></TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* <FerramentaNavegacaoExportacao
                                FerramentaNavegacaoExportacao={true}
                                // exportToPDF={exportToPDF}
                                // activeImpressao={activeImpressao}
                                print={print}
                                configLista={configLista}
                                lista={solicitacoes}
                                page={page}
                                setPage={setPage}
                            /> */}
            </Card>
            :
            <ListaVazia />
          :
          <Loading text={'A carregar solicitações'} />
        }
      </Card>
    </Container>

  )
}

export default ListaSolicitacoesDesalfandegamento 
