import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
} from "@mui/material";
import HeaderSession from "../../utils/headerSession";

interface Categoria {
  _id: string;
  nome: string;
  subcategorias?: Categoria[];
}

interface NovoMedicamento {
  nomeGenerico: string;
  codNacional: string;
  codUNSPSC: string;
  apresentacao: string;
  categoria: string;
  utilizacaoMedicamento: string[];
}

const AdicionarMedicamento: React.FC = () => {
  const [categoriasHierarquicas, setCategoriasHierarquicas] = useState<
    Categoria[]
  >([]);
  const [utilizacoesOpcoes, setUtilizacoesOpcoes] = useState<
    { _id: string; tipo: string }[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [novoMedicamento, setNovoMedicamento] = useState<NovoMedicamento>({
    nomeGenerico: "",
    codNacional: "",
    codUNSPSC: "",
    apresentacao: "",
    categoria: "",
    utilizacaoMedicamento: [],
  });
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Carregar categorias e utilizações da API
  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      api.get("/categorias"), // Substitui getCategorias
      api.get("/utilizacao-Medicamento"), // Substitui getUtilizacoesMedicamento
    ])
      .then(([categoriasRes, utilizacoesRes]) => {
        setCategoriasHierarquicas(categoriasRes.data.categorias || []);
        const utilizacoes =
          utilizacoesRes.data.utilizacaoMedicamento?.map((item: any) => ({
            _id: item._id,
            tipo: item.tipo,
          })) || [];
        setUtilizacoesOpcoes(utilizacoes);
      })
      .catch((err) => {
        console.error("Erro ao carregar dados:", err);
        setError("Não foi possível carregar as informações. Tente novamente.");
      })
      .finally(() => setIsLoading(false));
  }, []);

  // Função recursiva para gerar nomes hierárquicos
  const gerarNomesHierarquicos = (
    categorias: Categoria[],
    prefixo: string = ""
  ): { _id: string; nome: string }[] => {
    return categorias.flatMap((categoria, index) => {
      const numeroAtual = `${prefixo}${index + 1}`;
      const nomeAtual = `${numeroAtual}. ${categoria.nome}`;
      const filhos =
        categoria.subcategorias && categoria.subcategorias.length > 0
          ? gerarNomesHierarquicos(categoria.subcategorias, `${numeroAtual}.`)
          : [];

      return [{ _id: categoria._id, nome: nomeAtual }, ...filhos];
    });
  };

  // Renderizar categorias enumeradas
  const categoriasEnumeradas = gerarNomesHierarquicos(categoriasHierarquicas);

  // Submeter os dados do medicamento
  // Função de envio do formulário
  const handleSubmit = () => {
    if (!novoMedicamento.nomeGenerico || !novoMedicamento.categoria) {
      setError("Preencha todos os campos obrigatórios.");
      return;
    }

    console.log("Dados do novo medicamento:", novoMedicamento); // Verifique os dados do medicamento

    // Garantir que o array de utilizacoesMedicamento esteja pronto para ser enviado (contendo IDs das utilizações selecionadas)
    const dadosParaEnvio = {
      ...novoMedicamento,
      utilizacaoMedicamento: novoMedicamento.utilizacaoMedicamento || [], // Garante que a lista de utilizações não seja nula
    };

    // Enviar os dados para a API
    api
      .post("/medicamento/create", dadosParaEnvio) // Substitui adicionarMedicamento
      .then(() => {
        alert("Medicamento adicionado com sucesso!");
        navigate("/admin/Tabela-Geral");
      })
      .catch((err) => {
        console.error("Erro ao adicionar medicamento:", err);
        setError("Erro ao adicionar o medicamento. Tente novamente.");
      });
  };

  // Lógica para exibir os checkboxes de utilização
  {
    utilizacoesOpcoes.map(({ _id, tipo }) => (
      <FormControlLabel
        key={_id}
        control={
          <Checkbox
            checked={novoMedicamento.utilizacaoMedicamento.includes(_id)} // Verifica se o ID está na lista de selecionados
            onChange={() => {
              setNovoMedicamento((prev) => {
                const novaUtilizacoes = prev.utilizacaoMedicamento.includes(_id)
                  ? prev.utilizacaoMedicamento.filter((id) => id !== _id) // Remove da lista se já estiver selecionado
                  : [...prev.utilizacaoMedicamento, _id]; // Adiciona à lista se não estiver selecionado

                console.log("Utilizações selecionadas:", novaUtilizacoes); // Verifique se as utilizacões estão sendo salvas corretamente
                return { ...prev, utilizacaoMedicamento: novaUtilizacoes };
              });
            }}
          />
        }
        label={tipo} // Exibe o nome da utilização
      />
    ));
  }

  return (
    <Container>
      <HeaderSession title="Adicionar Novo Medicamento" />
      <Box sx={{ backgroundColor: "white", borderRadius: "5px" }}>
        <Box sx={{ backgroundColor: "white", borderRadius: "5px" }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box>
              {error && (
                <Typography color="error" gutterBottom>
                  {error}
                </Typography>
              )}

              <TextField
                label="Nome Genérico *"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.nomeGenerico}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    nomeGenerico: e.target.value,
                  }))
                }
              />
              <div style={{ display: "flex", gap: "20px" }}>
                <TextField
                  label="Código Nacional"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={novoMedicamento.codNacional}
                  onChange={(e) =>
                    setNovoMedicamento((prev) => ({
                      ...prev,
                      codNacional: e.target.value,
                    }))
                  }
                />

                <TextField
                  label="Código UNSPSC"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={novoMedicamento.codUNSPSC}
                  onChange={(e) =>
                    setNovoMedicamento((prev) => ({
                      ...prev,
                      codUNSPSC: e.target.value,
                    }))
                  }
                />
              </div>

              <TextField
                label="Apresentação"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.apresentacao}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    apresentacao: e.target.value,
                  }))
                }
              />

              <Autocomplete
                options={categoriasEnumeradas}
                getOptionLabel={(option) => option.nome}
                noOptionsText="Nenhuma categoria encontrada"
                onChange={(_, value) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    categoria: value?._id || "",
                  }))
                }
                value={
                  categoriasEnumeradas.find(
                    (cat) => cat._id === novoMedicamento.categoria
                  ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Categoria *"
                    variant="outlined"
                  />
                )}
                fullWidth
              />

              <Typography variant="h6" marginTop="1rem">
                Áreas de Utilização
              </Typography>
              {utilizacoesOpcoes.map(({ _id, tipo }) => (
                <FormControlLabel
                  key={_id}
                  control={
                    <Checkbox
                      checked={novoMedicamento.utilizacaoMedicamento.includes(
                        _id
                      )}
                      onChange={() => {
                        // Atualiza o estado com as utilizações selecionadas
                        setNovoMedicamento((prev) => {
                          const novaUtilizacoes =
                            prev.utilizacaoMedicamento.includes(_id)
                              ? prev.utilizacaoMedicamento.filter(
                                (id) => id !== _id
                              )
                              : [...prev.utilizacaoMedicamento, _id];
                          console.log(
                            "Utilizações selecionadas:",
                            novaUtilizacoes
                          ); // Verifique se as utilizacões estão sendo salvas corretamente
                          return {
                            ...prev,
                            utilizacaoMedicamento: novaUtilizacoes,
                          };
                        });
                      }}
                    />
                  }
                  label={tipo}
                />
              ))}

              <Box marginTop="2rem">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ marginRight: "1rem" }}
                >
                  Salvar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate("/ListaMedicamentos")}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          )}
        </Box>

        <Box sx={{ backgroundColor: "white", padding: '1rem', borderRadius: '5px' }}>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <Box>
              {error && (
                <Typography color="error" gutterBottom>
                  {error}
                </Typography>
              )}

              <TextField
                label="Nome Genérico *"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.nomeGenerico}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    nomeGenerico: e.target.value,
                  }))
                }
              />

              <TextField
                label="Código Nacional"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.codNacional}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    codNacional: e.target.value,
                  }))
                }
              />

              <TextField
                label="Código UNSPSC"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.codUNSPSC}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    codUNSPSC: e.target.value,
                  }))
                }
              />

              <TextField
                label="Apresentação"
                variant="outlined"
                fullWidth
                margin="normal"
                value={novoMedicamento.apresentacao}
                onChange={(e) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    apresentacao: e.target.value,
                  }))
                }
              />

              <Autocomplete
                options={categoriasEnumeradas}
                getOptionLabel={(option) => option.nome}
                noOptionsText="Nenhuma categoria encontrada"
                onChange={(_, value) =>
                  setNovoMedicamento((prev) => ({
                    ...prev,
                    categoria: value?._id || "",
                  }))
                }
                value={
                  categoriasEnumeradas.find(
                    (cat) => cat._id === novoMedicamento.categoria
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Categoria *" variant="outlined" />
                )}
                fullWidth
              />

              <Typography variant="h6" marginTop="1rem">
                Áreas de Utilização
              </Typography>
              {utilizacoesOpcoes.map(({ _id, tipo }) => (
                <FormControlLabel
                  key={_id}
                  control={
                    <Checkbox
                      checked={novoMedicamento.utilizacaoMedicamento.includes(
                        _id
                      )}
                      onChange={() => {
                        // Atualiza o estado com as utilizações selecionadas
                        setNovoMedicamento((prev) => {
                          const novaUtilizacoes =
                            prev.utilizacaoMedicamento.includes(_id)
                              ? prev.utilizacaoMedicamento.filter(
                                (id) => id !== _id
                              )
                              : [...prev.utilizacaoMedicamento, _id];
                          console.log(
                            "Utilizações selecionadas:",
                            novaUtilizacoes
                          ); // Verifique se as utilizacões estão sendo salvas corretamente
                          return {
                            ...prev,
                            utilizacaoMedicamento: novaUtilizacoes,
                          };
                        });
                      }}
                    />
                  }
                  label={tipo}
                />
              ))}

              <Box marginTop="2rem">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ marginRight: "1rem" }}
                >
                  Salvar
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => navigate("/ListaMedicamentos")}
                >
                  Cancelar
                </Button>
              </Box>
            </Box>
          )}

        </Box >
      </Box >
    </Container >
  );
};

export default AdicionarMedicamento;
