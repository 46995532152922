import React, { useEffect, useState, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Checkbox,
  CircularProgress,
  Box,
  Snackbar,
  Alert,
  Container,
} from "@mui/material";
import {
  ExpandMore,
  ExpandLess,
  Add,
  Delete,
  DataSaverOn,
} from "@mui/icons-material";
import { Categoria } from "./types/typesListaCategorias";
import api from "../../services/api";
import HeaderSession from "../../utils/headerSession";

const TabelaCategorias: React.FC = () => {
  const [categorias, setCategorias] = useState<Categoria[]>([]);
  const [categoriasAbertas, setCategoriasAbertas] = useState<{
    [key: string]: boolean;
  }>({});
  const [categoriasSelecionadas, setCategoriasSelecionadas] = useState<{
    [key: string]: boolean;
  }>({});
  const [termoBusca, setTermoBusca] = useState<string>("");
  const [novaCategoria, setNovaCategoria] = useState<{
    nome: string;
    descricao: string;
    pai: string | null;
  }>({
    nome: "",
    descricao: "",
    pai: null,
  });
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [caminhoCategoria, setCaminhoCategoria] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDeleting, setIsDeleting] = useState<{ [key: string]: boolean }>({});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );

  const showSnackbar = (message: string, severity: "success" | "error") => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    api
      .get("/categorias")
      .then((response) => {
        setCategorias(response.data.categorias);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao buscar categorias:", error);
        setIsLoading(false);
        showSnackbar(
          "Erro ao carregar categorias. Tente novamente mais tarde.",
          "error"
        );
      });
  }, []);

  const toggleDialog = () => setDialogOpen(!isDialogOpen);

  const handleAdicionarCategoria = () => {
    if (!novaCategoria.nome) {
      showSnackbar("Por favor, preencha o nome.", "error");
      return;
    }

    const categoria = {
      nome: novaCategoria.nome,
      descricao: novaCategoria.descricao,
      pai: novaCategoria.pai,
    };

    api
      .post("/categoria/create", categoria)
      .then((response) => {
        const categoriaCriada = response.data;
        setCategorias((prevCategorias) => {
          if (categoria.pai) {
            return prevCategorias.map((cat) => {
              if (cat._id === categoria.pai) {
                return {
                  ...cat,
                  subcategorias: [...cat.subcategorias, categoriaCriada],
                };
              }
              return cat;
            });
          } else {
            return [...prevCategorias, categoriaCriada];
          }
        });

        toggleDialog();
        setNovaCategoria({ nome: "", descricao: "", pai: null });
        showSnackbar("Categoria adicionada com sucesso.", "success");
      })
      .catch((error) => {
        console.error("Erro ao adicionar categoria:", error);
        const errorMessage =
          error.response?.data?.message || "Erro ao adicionar categoria.";
        showSnackbar(errorMessage, "error");
      });
  };

  const alternarAbertura = (id: string) =>
    setCategoriasAbertas((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

  const handleCheckboxChange = (id: string) =>
    setCategoriasSelecionadas((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));

  const gerarCaminhoCategoria = (id: string | null): string => {
    const encontrarCaminho = (
      categoria: Categoria,
      caminho: string[]
    ): string[] | null => {
      if (categoria._id === id) return [...caminho, categoria.nome];
      return categoria.subcategorias.reduce<string[] | null>(
        (acc, subcategoria) =>
          acc || encontrarCaminho(subcategoria, [...caminho, categoria.nome]),
        null
      );
    };

    const caminho = categorias.reduce<string[] | null>(
      (acc, categoria) => acc || encontrarCaminho(categoria, []),
      null
    );
    return Array.isArray(caminho)
      ? caminho.join(" > ")
      : "Categoria não encontrada";
  };

  const handleOpenSubcategoriaDialog = (pai: string) => {
    setNovaCategoria({ nome: "", descricao: "", pai });
    setCaminhoCategoria(gerarCaminhoCategoria(pai));
    setDialogOpen(true);
  };

  const handleDeletarCategoria = (id: string) => {
    setIsDeleting((prev) => ({ ...prev, [id]: true }));
    api
      .delete(`/categoria/${id}`)
      .then(() => {
        setCategorias((prevCategorias) =>
          prevCategorias.filter((categoria) => categoria._id !== id)
        );
        showSnackbar("Categoria excluída com sucesso.", "success");
      })
      .catch((error) => {
        console.error("Erro ao excluir categoria:", error);
        showSnackbar(
          error.response?.data?.message || "Erro ao excluir categoria.",
          "error"
        );
      })
      .finally(() => {
        setIsDeleting((prev) => ({ ...prev, [id]: false }));
      });
  };

  const categoriasFiltradas = categorias
    .filter((categoria) => categoria.pai === null)
    .filter((categoria) =>
      [categoria.nome, categoria.descricao].some((campo) =>
        campo?.toLowerCase().includes(termoBusca.toLowerCase())
      )
    );

  const renderizarLinhasCategoria = useCallback(
    (categoria: Categoria, nivel = 0, numero: string) => {
      const aberta = categoriasAbertas[categoria._id];
      const selecionada = categoriasSelecionadas[categoria._id];
      const subcategorias = categoria.subcategorias || [];

      return (
        <React.Fragment key={categoria._id}>
          
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                checked={!!selecionada}
                onChange={() => handleCheckboxChange(categoria._id)}
              />
            </TableCell>
            <TableCell>{numero}</TableCell>
            <TableCell style={{ paddingLeft: nivel * 20 }}>
              {subcategorias.length > 0 && (
                <IconButton
                  onClick={() => alternarAbertura(categoria._id)}
                  size="small"
                >
                  {aberta ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
              {categoria.nome}
            </TableCell>
            <TableCell>{categoria.descricao}</TableCell>
            <TableCell>
              {new Date(categoria.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell>
              {new Date(categoria.updatedAt).toLocaleDateString()}
            </TableCell>
            <TableCell>
              {selecionada && (
                <>
                  <Tooltip title="Adicionar Subcategoria">
                    <IconButton
                      onClick={() =>
                        handleOpenSubcategoriaDialog(categoria._id)
                      }
                    >
                      <Add />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Excluir Categoria">
                    <IconButton
                      onClick={() => handleDeletarCategoria(categoria._id)}
                      disabled={isDeleting[categoria._id]}
                    >
                      {isDeleting[categoria._id] ? (
                        <CircularProgress size={24} />
                      ) : (
                        <Delete />
                      )}
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </TableCell>
          </TableRow>
          {aberta &&
            subcategorias.map((subcategoria, index) =>
              renderizarLinhasCategoria(
                subcategoria,
                nivel + 1,
                `${numero}.${index + 1}`
              )
            )}
        </React.Fragment>
      );
    },
    [
      categoriasAbertas,
      categoriasSelecionadas,
      handleCheckboxChange,
      alternarAbertura,
      handleOpenSubcategoriaDialog,
      handleDeletarCategoria,
      isDeleting,
    ]
  );

  return (
    <Container>
      <HeaderSession
                  title="Tabela de Categorias de Medicamentos"
                />
      <Box
        display={"flex"}
        flexDirection={"column"}
        borderRadius={"5px"}
        gap={"2rem"}
        border={"1px"}
        borderColor={"GrayText"}
        padding={"1rem"}
        bgcolor={"white"}
      >
        <div style={{ display: "flex", gap: "2rem", alignItems: "center" }}>
          <TextField
            label="Buscar Categoria"
            variant="outlined"
            fullWidth
            value={termoBusca}
            onChange={(e) => setTermoBusca(e.target.value)}
          />
          <Button
            variant="contained"
            sx={{ height: "3.5rem", backgroundColor: "#85287e" }}
            onClick={() => {
              setNovaCategoria({ nome: "", descricao: "", pai: null });
              setCaminhoCategoria("");
              toggleDialog();
            }}
          >
            <DataSaverOn sx={{ height: "100%" }} />
          </Button>
        </div>
        <TableContainer sx={{ borderRadius: "5px", border:'1px', borderColor:'black' }}>
          <Table sx={{ borderRadius: "5px", border:'1px', borderColor:'black' }}>
            <TableHead sx={{ backgroundColor: "gray-600", border:'1px', borderColor:'black' }}>
              <TableRow>
                <TableCell padding="checkbox" />
                <TableCell>#</TableCell>
                <TableCell>Nome</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Criado em</TableCell>
                <TableCell>Atualizado em</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              ) : categoriasFiltradas.length > 0 ? (
                categoriasFiltradas.map((categoria, index) =>
                  renderizarLinhasCategoria(categoria, 0, `${index + 1}`)
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography>Nenhuma categoria encontrada.</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Dialog open={isDialogOpen} onClose={toggleDialog}>
          <DialogTitle>Adicionar Categoria</DialogTitle>
          <DialogContent>
            {novaCategoria.pai && (
              <Typography>Categoria Pai: {caminhoCategoria}</Typography>
            )}
            <TextField
              label="Nome"
              variant="outlined"
              fullWidth
              margin="normal"
              value={novaCategoria.nome}
              onChange={(e) =>
                setNovaCategoria((prev) => ({ ...prev, nome: e.target.value }))
              }
            />
            <TextField
              label="Descrição"
              variant="outlined"
              fullWidth
              margin="normal"
              value={novaCategoria.descricao}
              onChange={(e) =>
                setNovaCategoria((prev) => ({
                  ...prev,
                  descricao: e.target.value,
                }))
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={toggleDialog}>Cancelar</Button>
            <Button onClick={handleAdicionarCategoria} variant="contained">
              Adicionar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={4000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Container>
  );
};

export default TabelaCategorias;
