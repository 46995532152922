import { Route } from "react-router-dom";
import ProtectedRoute from "./protectedRouter";
import TemplateAdmin from "../admin/template/templateAdmin";
import Dashboard from "../admin/dashboard/dashboad";
import MenuDlif from "../admin/departamentos/dlif/menuDlif";
import CategoriasEstabelecimento from "../admin/gestLicenciados/categorias/categoriasEstabelecimento";
import CadastroEstabelecimento from "../admin/departamentos/dlif/licenciamento/cadastroEstabelecimento/cadastroEstabelecimentoAdmin";
import ResumoProvincia from "../admin/dashboard/resumoProvincia";
import CombinePedido from "../admin/departamentos/dlif/licenciamento/combineSolicitacoes";
import Estabelecimentosbytipo from "../admin/gestLicenciados/estabelecimentosbytipo";
import InspeccionarEstabelecimento from "../admin/departamentos/dlif/inspeccao/avfd/inspeccionarEstabelecimento";
import EmissaoAutorizacaoExercicio from "../admin/departamentos/dlif/licenciamento/emissaoAutorizacaoExercicio";
import SolicitacoesReprovadas from "../admin/departamentos/dlif/licenciamento/solicitacoesReprovadas";
import TecnicoArea from "../admin/departamentos/dlif/licenciamento/areas/tecnico";
import ChefedaseccaoArea from "../admin/departamentos/dlif/licenciamento/areas/chefedaseccao";
import ChefedodepartamentoArea from "../admin/departamentos/dlif/licenciamento/areas/chefedodepartamento";
import InspeccaoAgendadaArea from "../admin/departamentos/dlif/licenciamento/areas/inspeccaoAgendada";
import DirectorGeralArea from "../admin/departamentos/dlif/licenciamento/areas/directorGeral";
import SolicitacoesDeLicenciamento from "../admin/departamentos/dlif/licenciamento/solicitacoesDeLicenciamento";
import ModoInspeccao from "../admin/departamentos/dlif/inspeccao/modoInspeccao";
import InspeccaoByInspector from "../admin/departamentos/dlif/inspeccao/inspeccaoByInspector";
import Inspecao from "../admin/departamentos/dlif/inspeccao/avfd/createInspecao";
import GestPedidosLicenciamento from "../admin/departamentos/dlif/licenciamento";
import ChangeStatus from "../admin/departamentos/dlif/licenciamento/changeStatus";
import SolicitacoesRenovacao from "../admin/departamentos/dlif/renovacao/solicitacoesRenovacao";
import Observacoes from "../admin/gestLicenciados/observacoes";
import ResponsavelDeRegisto from "../admin/gestLicenciados/responsavelDeRegisto";

// INICIO GEST-AGENDA 
import GestAgenda from "../admin/gestAgenda";
import AgendaDisponivel from "../admin/gestAgenda/agendaDisponivel";
import CreateAgenda from "../admin/gestAgenda/calendario/createAgenda";
import AgendaMarcada from "../admin/gestAgenda/agendaMarcada";

// Gest-ADMINISTRADORES *
import ListaFuncionarios from "../admin/gestAdmin/listaFuncionarios";

import GestNoticias from "../admin/gestInformacao/noticias";

import HistoricosActividades from "../admin/gestAdmin/historicosDeActividadesAdmin";
import CadastroAdmin from "../admin/gestAdmin/cadastroAdmin";
import ManualUso from "../utils/manualUsoGestor";

// import ViewDocDownload from "../utils/viewDocDownload";
import CentralImpressaoQRcode from "../admin/centralImpressao/centralImpressaoQRcode";

import PerfilAdmin from "../admin/gestAdmin/perfil";

// import { Feedback } from "@material-ui/icons";
import WebAccess from "../admin/gestAdmin/webAccess";
// import GetFeedback from "../admin/gestAdmin/getFeedback";
import GestBanner from "../admin/gestInformacao/banner";

// import AutorizacoesExercicioEmitidas from "../admin/gestLicenciados/autorizacoesExercicioEmitidas";
import TemplateRelatorioAutorizacoesExercicioEmitidas from "../admin/departamentos/dlif/relatorios/autorizacaoExercicio/templateRelatorioAutorizacoesExercicioEmitidas";

/* RELATORIO GERAL */
import RelatorioIndex from "../admin/departamentos/dlif/relatorios/relatorioGeral/relatorioIndex";
import TabelaCategorias from "../admin/gestMedicamentos/TabelaCategorias";
import AdicionarMedicamento from "../admin/gestMedicamentos/AdicionarMedicamento";
import MedicamentoDetalhado from "../admin/gestMedicamentos/MedicamentoDetalhes";
import TabelaMedicamentos from "../admin/gestMedicamentos/TabelaGeral";


const dlif = ["DLIF", "CNP", "DG"];

const DlifRoutes = ({ user }) => [

  <Route key="dashboard" path="/admin/dashboard" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath="/login"> <TemplateAdmin element={<Dashboard />} /> </ProtectedRoute>} />,
  <Route key="menu-dlif" path="/admin/dlif" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath="/login" > <TemplateAdmin element={<MenuDlif />} /></ProtectedRoute>} />,
  <Route key="cadastro-estabelecimento" path="/admin/cadastro-de-estabelecimento" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath="/login"> <TemplateAdmin element={<CadastroEstabelecimento />} /> </ProtectedRoute>} />,
  <Route key="categorias-estabelecimento" path="/admin/categorias-de-estabelecimento" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath="/login"> <TemplateAdmin element={<CategoriasEstabelecimento />} /></ProtectedRoute>} />,

  <Route key="01" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ResumoProvincia />} /> </ProtectedRoute>} path="/admin/provincia/:provincia" />,
  <Route key="02" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<Estabelecimentosbytipo />} /> </ProtectedRoute>} path="/admin/provincia/:provincia/:estabelecimentoTipoId" />,

  // {/* PEDIDOS DE LICENCIAMENTO*/}
  <Route key="04" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<SolicitacoesDeLicenciamento />} /> </ProtectedRoute>} path="/admin/solicitacoes-de-licenciamento" />,
  <Route key="05" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<TecnicoArea />} /> </ProtectedRoute>} path="/admin/recepcao/solicitacoes-de-licenciamento" />,
  <Route key="06" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ChefedaseccaoArea />} /> </ProtectedRoute>} path="/admin/chefe-da-seccao/solicitacoes-de-licenciamento" />,
  <Route key="07" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ChefedodepartamentoArea />} /> </ProtectedRoute>} path="/admin/chefe-do-departamento/solicitacoes-de-licenciamento" />,
  <Route key="08" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<InspeccaoAgendadaArea />} /> </ProtectedRoute>} path="/admin/inspeccao-agendada/solicitacoes-de-licenciamento" />,
  <Route key="09" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<DirectorGeralArea />} /> </ProtectedRoute>} path="/admin/director-geral/solicitacoes-de-licenciamento" />,
  <Route key="010" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<SolicitacoesReprovadas />} /> </ProtectedRoute>} path="/admin/solicitacoes/reprovadas" />,

  // {/* PEDIDOS DE RENOVACOES*/}
  <Route key="011" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<SolicitacoesRenovacao />} /> </ProtectedRoute>} path="/admin/solicitacoes-de-renovacao" />,

  // {/* INSPECCAO */}
  <Route key="012" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<InspeccaoByInspector />} /> </ProtectedRoute>} path="/admin/minhas-inspeccoes" />,
  <Route key="013" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <ModoInspeccao element={<InspeccionarEstabelecimento />} /> </ProtectedRoute>} path="/admin/modo-inspeccao/:id" />,

  <Route key="014" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<CombinePedido />} />} /> </ProtectedRoute>} path="/admin/analise/solicitacao-de-licenciamento/:id" />,
  <Route key="015" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<ChangeStatus />} />} /> </ProtectedRoute>} path="/admin/:encaminhar/:status/:id" />,
  <Route key="016" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<ResponsavelDeRegisto />} />} /> </ProtectedRoute>} path="/admin/responsavel-registo/:id" />,
  <Route key="017" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<Observacoes />} />} /> </ProtectedRoute>} path="/admin/observacoes/:id" />,
  <Route key="018" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<Inspecao />} />} /> </ProtectedRoute>} path="/admin/inspecao/:encaminhar/:id" />,
  <Route key="019" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<InspeccionarEstabelecimento />} />} /> </ProtectedRoute>} path="/admin/inspeccionar-estabelecimento/:id" />,
  <Route key="020" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestPedidosLicenciamento element={<EmissaoAutorizacaoExercicio />} />} /> </ProtectedRoute>} path="/admin/emissao/autorizacao/:id" />,

  // {/* CADASTROS */}
  <Route key="021" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CadastroEstabelecimento />} /> </ProtectedRoute>} path="/admin/cadastro-de-estabelecimento" />,
  <Route key="022" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CategoriasEstabelecimento />} /> </ProtectedRoute>} path="/admin/categorias-de-estabelecimento" />,



  // {/* RELATORIOS */}
  <Route key="023" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<RelatorioIndex />} /> </ProtectedRoute>} path="/admin/dlif/relatorios/geral" />,
  <Route key="024" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<TemplateRelatorioAutorizacoesExercicioEmitidas />} /> </ProtectedRoute>} path="/autorizacoes-de-exercicio-emitidas/geral" />,
  <Route key="025" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<RelatorioIndex />} /> </ProtectedRoute>} path="/relatorio-geral/geral" />,


  // {/* CENTRAL-DE-IMPRESSAO */}
  // {/* <Route key=" " element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CentralImpressaoQRcode />} /> </ProtectedRoute>} path="/admin/central-impressao/qrcode" /> */}
  <Route key="026" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <CentralImpressaoQRcode /> </ProtectedRoute>} path="/admin/central-impressao/qrcode" />,

  // {/* Gest-AGENDAMENTO */}
  <Route key="027" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestAgenda element={<AgendaDisponivel />} />} /> </ProtectedRoute>} path="/admin/gestor-agenda" />,
  <Route key="028" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestAgenda element={<CreateAgenda />} />} /> </ProtectedRoute>} path="/admin/create-agenda" />,
  <Route key="029" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestAgenda element={<AgendaMarcada />} />} /> </ProtectedRoute>} path="/admin/agendas-marcadas" />,

  // {/* Gest-NOTICIAS */}
  <Route key="030" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestNoticias />} /> </ProtectedRoute>} path="/admin/gestor-noticias" />,
  // {/* Gest-BANNER */}
  <Route key="031" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GestBanner />} /> </ProtectedRoute>} path="/admin/gestor-banner" />,

  // {/* Gest-ADMINISTRADORES */}
  <Route key="032" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<PerfilAdmin />} /> </ProtectedRoute>} path="/admin/perfil-admin" />,
  <Route key="033" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ManualUso />} /> </ProtectedRoute>} path="/admin/manual-do-utilizador" />,
  <Route key="034" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<ListaFuncionarios />} /> </ProtectedRoute>} path="/admin/lista-de-usuarios-administradores" />,
  <Route key="035" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<CadastroAdmin />} /> </ProtectedRoute>} path="/admin/cadastro-administrador" />,
  <Route key="036" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<HistoricosActividades />} /> </ProtectedRoute>} path="/admin/historicos-de-actividades" />,
  // {/* <Route key=" " element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<GetFeedback />} /> </ProtectedRoute>} path="/admin/feedback" /> */}
  <Route key="037" element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<WebAccess />} /> </ProtectedRoute>} path="/admin/access-visity" />,

  // {/* Gest-MEDICAMENTOS */}

  <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<TabelaCategorias />} /> </ProtectedRoute>} path="/admin/tabela-categorias" />,

  <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<TabelaMedicamentos />} /> </ProtectedRoute>} path="/admin/tabela-geral" />,

  <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<AdicionarMedicamento />} /> </ProtectedRoute>} path="/adicionar-medicamento" />,

  <Route element={<ProtectedRoute departamentoAutorizado={dlif} isAuthenticated={user} redirectPath={'/login'} > <TemplateAdmin element={<MedicamentoDetalhado />} /> </ProtectedRoute>} path="/medicamento/:id" />,
];

export default DlifRoutes;
